/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /*
        |----------------------------------------------------------------
        |  Variables
        |----------------------------------------------------------------
        */
        $nav_wrap = ('.gn-wrap'),
        $nav_trigger = $('.gn--trigger'),
        $burger = $('.burger'),
        $global_nav = $('.gn'),
        $nav_top_level = $('.gn--items'),
        $nav_top_level_item   = $('.gn--items > .menu-item'),
        $nav_mobile_search   = $('.mobile-search'),
        $nav_item_with_subnav = $('.gn--items > li.menu-item-has-children'),
        $sub_nav_trigger    = $('<div class="sub-trigger-wrap"><div class="subnav-icon"><span></span></div></div>'),
        $submenu = $('.sub-menu'),
        $submenu_link = $('.sub-menu li a'),
        $active_sub_nav = $('li.active .sub-menu .active'),
        $ul = $('ul'),
        windowWidth = $('body').width(),
        targetElement = document.querySelector('.gn'),
        scrollPosition = $(window).scrollTop();

        /*
        |----------------------------------------------------------------
        |  Global Naviagation
        |----------------------------------------------------------------
        */
        function addSubNavIcon() {
            $($sub_nav_trigger).appendTo($nav_item_with_subnav);
        }

        function gnOpen() {
            var windowWidth = $(window).width();
            console.log('click');
            $($nav_wrap).addClass('expand-wrap');
            $($burger).addClass('burger--animate');
            $($global_nav).addClass('gn--open');
            $($nav_top_level_item).addClass('show-item');
            $($nav_mobile_search).addClass('show-item');
            $($active_sub_nav).parents($nav_item_with_subnav).addClass('slide-down-sub-nav');

            if ( ( $($active_sub_nav).length > 0) ) {

                if(windowWidth < 768) {
                  $($active_sub_nav).parent($submenu).addClass('show-sub-nav').slideDown(0);
                } else {
                  $($active_sub_nav).parent($submenu).addClass('show-sub-nav').delay(250).fadeIn();
                }

                $($nav_top_level).addClass('move-off-gn');
                $($active_sub_nav).parent($ul).addClass('move-on-subnav').show();
            }

            bodyScrollLock.disableBodyScroll(targetElement);
        }

        function gnClose() {
            $($nav_wrap).removeClass('expand-wrap');

            if( !$($nav_top_level).hasClass('move-off-gn') ) {
                $($nav_top_level_item).removeClass('show-item');
                $($nav_mobile_search).removeClass('show-item');
                $($burger).removeClass('burger--animate');
                $($global_nav).removeClass('gn--open');
            } else {
                $($submenu).removeClass('show-sub-nav').fadeOut(300);
                $($burger).removeClass('burger--animate');
                $($global_nav).removeClass('gn--open');
                $($nav_top_level).removeClass('move-off-gn');
                $($nav_top_level_item).removeClass('show-item');
                $($nav_mobile_search).removeClass('show-item');
            }
            bodyScrollLock.enableBodyScroll(targetElement);
        }


        function subNav() {
            windowWidth = $(window).width();

            $($nav_item_with_subnav).unbind("click").on('click', function(event) {
                event.preventDefault();
                if ($(this).hasClass('slide-down-sub-nav'))  {
                    $(this).removeClass('slide-down-sub-nav');
                    $(this).find('.sub-menu').slideUp(450);
                } else {
                    event.stopPropagation();
                    $($nav_item_with_subnav).removeClass('slide-down-sub-nav').find('.sub-menu').slideUp();
                    $(this).addClass('slide-down-sub-nav');
                    $(this).find('.sub-menu').addClass('show-sub-nav').slideDown();
                }
            });
        }

        $($submenu_link).on('click', function(event) {
          event.stopPropagation();
        });

        function globalNav() {
            $($nav_trigger).on('click', function(event) {
            $(this).toggleClass('burger-active');
            event.preventDefault();

            if($($global_nav).hasClass('gn--open')) {
                gnClose();
                $($nav_item_with_subnav).removeClass('slide-down-sub-nav').find('.sub-menu').slideUp(400);
            } else {
                gnOpen();
            }
            });
        }

        /*
        |----------------------------------------------------------------
        | Desktop Search
        |----------------------------------------------------------------
        */
        var $searchTrigger = $('.gn .icon-holder');

        $($searchTrigger).unbind().on('click', function(e) {
          e.preventDefault();
          var windowWidth = $('body').width();
          if(windowWidth > 1199) {
            $('.mobile-search').toggleClass('is-showing');
          }
        });

        /*
        |----------------------------------------------------------------
        | Flickity
        |----------------------------------------------------------------
        */
        function contentSlider($slider, $dots, $responsive) {
          $($slider).flickity({
              wrapAround: false,
              pageDots: $dots,
              prevNextButtons: false,
              watchCSS: $responsive,
          });
        }

        contentSlider('.gallery .image-slider', true, true);


        /*
        |----------------------------------------------------------------
        |  Lightbox
        |----------------------------------------------------------------
        */
        if($('.gallery').length) {
          $('.gallery .img a').simpleLightbox();
        }

        /*
        |----------------------------------------------------------------
        |  Load all the functions
        |----------------------------------------------------------------
        */
        function onWindowResize() {
            var windowWidth = $(window).width();
            subNav();
        }

        globalNav();
        subNav();
        addSubNavIcon();
        onWindowResize();
        $(window).resize(onWindowResize);


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).load(function(){
            console.log('firing');
            $.fn.matchHeight._update();
            $('.theme-wrap').addClass('show-theme');
        });
      }
    },
    // Home page
    'page_template_template_pilots': {
      init: function() {

        /*
        |----------------------------------------------------------------
        |  Accordion
        |----------------------------------------------------------------
        */
        var $contentTrigger = $('.scg-heading-col');

        $($contentTrigger).unbind().on('click', function(e) {
          e.preventDefault();
          var windowWidth = $('body').width();
          console.log(windowWidth);
          if(windowWidth < 767) {
            $(this).next('.content').slideToggle(500);
            $(this).children('.subnav-icon').toggleClass('is-active');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_contact': {
      init: function() {

        // var $map = $("#google-map");
        // var bounds = new google.maps.LatLngBounds();

        // var map = new google.maps.Map(document.getElementById('google-map'), {
        //     styles: $map.data('style') || {},
        //     zoom: 15,
        //     scrollwheel: false,
        //     mapTypeControl: false,
        //     draggable: true,
        //     zoomControl: true,
        //     scaleControl: false,
        //     streetViewControl: false,
        //     rotateControl: false,
        //     fullscreenControl: false
        // });

        // var location = {
        //     lat: parseFloat($map.data('lat')),
        //     lng: parseFloat($map.data('lng')),
        //     url: $map.data('url'),
        // };

        // var marker = new google.maps.Marker({
        //     position: location,
        //     map: map,
        //     icon: $map.data('marker')
        // });

        // bounds.extend(location);

        // google.maps.event.addListener(marker, 'click', function() {
        //     window.location = 'http://maps.google.com?q='+location.url;
        // });

        // google.maps.event.addDomListener(window, 'resize', function() {
        //     var center = map.getCenter()
        //     google.maps.event.trigger(map, "resize")
        //     map.setCenter(center)
        // })

        // map.setCenter(bounds.getCenter());
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
